import React from 'react'
import { connect } from 'react-redux'
import { getAuthCompany, getAuthUser } from 'Store/Models/Users/selectors'
import { withRouter } from 'react-router-dom'
import { loadAuthUser } from 'Store/Models/Users/actions'
import Loadable from 'react-loadable'
import Loader from 'Shared/Components/loader'

const MainApp = Loadable({
	loader: () => import( /* webpackChunkName: "mainApp" */ 'Components/app/index'),
	loading: Loader,
	delay: 300
})

const ExpiredAccount = Loadable({
	loader: () => import( /* webpackChunkName: "expiredAccount" */ 'Components/app/inactive/index'),
	loading: Loader,
	delay: 300
})

const Login = Loadable({
	loader: () => import( /* webpackChunkName: "login" */ 'Components/Login/index'),
	loading: Loader,
	delay: 300
})

class App extends React.Component {
	componentWillMount = () => {
		this.props.dispatch(loadAuthUser())
	}

	render() {
		const { isLoggedIn, active, ActingAs } = this.props

		if (isLoggedIn === true) {
			
			//if we are active, or acting as let us in
			if (active || ActingAs) {
				return <MainApp />
			}
			
			return <ExpiredAccount />
		}

		if (isLoggedIn === false) {
			return <Login />
		}

		return null
	}
}
export default withRouter(
	connect(state => {
		const company = getAuthCompany(state)

		return {
			isLoggedIn: state.getIn(['Models', 'User', 'META', 'logged_in'], 'not_set'),
			active: company.get('active') === '1',
			ActingAs: getAuthUser(state).get('acting_as'),
		}
	})(App)
)
