import {Map} from 'immutable'

export const getUsers = function(state) {
	return state.getIn(['Models', 'User'], false).filter( (user, key) => (key !== 'META'))
}

export const getAuthUser = function(state) {
	let id = state.getIn(['Models', 'User', 'META', 'auth_user_id'], false)

	if( ! id )
	{
		return new Map()
	}

	return state.getIn(['Models', 'User', id.toString()], Map())
}

export const getAuthCompany = function(state) {
	const user = getAuthUser(state)
	const companyId = user.get('company_id', null)

	return state.getIn(['Models', 'Company', companyId], new Map())
}

export const isSuperAdmin = function(state) {
	const user = getAuthUser(state)

	return user.get('group_id') === '1'
}

export const getPermission = function(state, permission) {
	const user = getAuthUser(state)
	const userPerm = user.getIn(['permissions', permission], 0);
	return (userPerm === 1 || userPerm === '1' || userPerm === true);
}

//TODO fix case in currency
export const getcurrencySymbol = function(state) {
	const company = getAuthCompany(state)

	return company.get('currency_symbol', '$')
}

export const getDataForServer = function( state, id ) {
	const user = getUser(state, id)

	return {
		user_id   : user.get('id'),
		username  : user.get('username'),
		first_name: user.get('first_name'),
		last_name : user.get('last_name'),
		role      : user.get('role'),
		password  : user.get('password', ''),
	}
}
